<template>
	<div class="app-container">
		<p style="text-align: center; margin: 0 0 20px">{{translate('ROLE_MENU.FOR_ROLE')}}}<span style="color: red">{{roleId}} - {{role.name}}</span>{{translate('ROLE_MENU.ASSIGNED_MENU')}}</p>
		<el-row :gutter="24" style="min-height: 450px">
			<el-col :span="10" :offset="1">
				<h1>{{translate("ROLE_MENU.MENU_TREE")}}</h1>
				<el-input
					:placeholder="translate('FROM.PLACEHOLDER', {param: 'COMMON.KEYWORD'})"
					v-model="filterText">
				</el-input>
				<el-tree
					ref="tree"
					:props="treeProps"
					:data="menuTree"
					:node-key="menuId"
					default-expand-all
					:filter-node-method="filterNode"
					@node-click="handleNodeClick"/>
			</el-col>
			<el-col :span="10" :offset="1" v-show="!!menuId">
				<div v-show="menuId !== 'other'">
					<h1>{{translate('ROLE_MENU.SHOW_MENU')}}</h1>
					<el-checkbox v-model="selectMenu" border>{{translate('ROLE_MENU.SHOW_MENU')}} - <span style="color: red">{{currentMenu.name}}</span></el-checkbox>
				</div>
				<div v-show="menuAuths.length > 0">
					<h1>{{translate('ROLE_MENU.MENU_AUTHORITY')}}</h1>
					<el-checkbox :indeterminate="isIndeterminate" v-model="selectAllAuth" @change="handlerAllAuthChange">{{translate('COMMON.ALL')}}</el-checkbox>
					<div style="margin: 15px 0;"></div>
					<el-checkbox-group v-model="checkedAuthCodes" @change="handleCheckedAuthChange" class="auth_checkbox_group">
						<el-checkbox v-for="auth in menuAuths" :key="auth.code" :label="auth.code" >
						<span v-if="auth.type === 'OPERATE'" style="color: red">
						{{auth.name}}
						</span>
							<span v-else>
						{{auth.name}}
						</span>
						</el-checkbox>
					</el-checkbox-group>
				</div>
			</el-col>
		</el-row>
		<el-row>
			<el-col :span="8" :offset="8" v-show="!!menuId">
				<el-button type="primary" style="width: 300px;" @click="updateRoleMenuAuth">{{translate('FROM.SUBMIT')}}</el-button>
			</el-col>
		</el-row>
	</div>
</template>

<script>
	import {UserApi} from '@/api';
	import {CommonApi} from "../../../api";

	export default {
		name: "Index",
		data(){
			return{
				treeProps: {
					label: 'name',
				},
				roleId: 0,
				role: {},
				roleCode: '',
				menuId: '',
				currentMenu: {},
				selectMenu: true,
				menuTree: [],
				filterText: '',
				checkedAuthCodes:[],
				menuAuths: [],
				selectAllAuth: false,
				isIndeterminate: false
			}
		},
		async mounted(){
			this.roleId = this.$route.params.roleId;
			this.role = (await UserApi.role.detail(this.roleId)).data;
			this.roleCode = this.role.roleCode;
			this.listMenuTree();
		},
		watch: {
			filterText(val) {
				this.$refs.tree.filter(val);
			}
		},
		methods: {
			filterNode(value, data) {
				if (!value) return true;
				return data.name.indexOf(value) !== -1;
			},
			async listMenuTree(){
				this.menuTree = (await CommonApi.listMenuTreeByUser()).data || [];
				this.menuTree.push({name: this.translate("Other"), menuId: 'other'});
			},
			async handleNodeClick(node){
				this.menuId = node.menuId;
				this.currentMenu = node;
				let result = (await UserApi.role.listRoleMenuAuth(this.roleId, this.menuId)).data || {};
				this.menuAuths = result.menuAuthCode || [];
				this.selectMenu = !!result.hasTheMenu;
				this.checkedAuthCodes = (result.roleMenuAuthCode || []).map(item => item.code);
				this.selectAllAuth = this.checkedAuthCodes.length === this.menuAuths.length;
				this.isIndeterminate = this.checkedAuthCodes.length > 0 && this.checkedAuthCodes.length < this.menuAuths.length;
			},
			async updateRoleMenuAuth() {
				let result = await UserApi.role.updateRoleMenuAuth(this.selectMenu, this.roleId, this.menuId, this.checkedAuthCodes);
				if (result.success){
					this.$message.success(this.translate('COMMON.OPERATION_SUCCESS'))
				}
			},
			handlerAllAuthChange(val){
				this.checkedAuthCodes = val ? this.menuAuths.map(item => item.code) : [];
				this.isIndeterminate = false;
				this.selectMenu = val;
			},
			handleCheckedAuthChange(value){
				let checkedCount = value.length;
				this.selectAllAuth = checkedCount === this.menuAuths.length;
				this.isIndeterminate = checkedCount > 0 && checkedCount < this.menuAuths.length;
				if (checkedCount > 0) {
					this.selectMenu = true;
				}else if (checkedCount === 0) {
					this.selectMenu = false;
				}

			}
		}
	}
</script>

<style scoped>
	.auth_checkbox_group .el-checkbox{
		display: block;
		width: 200px;
		font-size: 24px;
	}
</style>
